import React from 'react';
import PropTypes from 'prop-types';
import OfflineLayout from '../components/layout/OfflineLayout';
import { changePassword, getProfile, login } from '../services/userService';
import LoginForm from '../components/login/LoginForm';
import ChangePasswordForm from '../components/login/ChangePasswordForm';
import {
  EMPTY_LOGIN_PASSWORD,
  EMPTY_NEW_PASSWORD,
  NEW_PASSWORDS_NO_MATCH,
  UNKNOWN_ERROR,
} from '../constants/login-error-messages';
import { ProfileFormLogin } from '../components/login/ProfileFormLogin';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
      isLoading: false,
      errors: null,
      isPasswordExpired: false,
      displayProfileForm: false,
    };
  }

  handleInput = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmitLogin = (event) => {
    event.preventDefault();

    const { onLogin, isReadOnly } = this.props;
    const { username, password } = this.state;

    this.setState({
      isLoading: true,
      errors: null,
    });

    if (!username || !password) {
      this.setState({
        isLoading: false,
        errors: [EMPTY_LOGIN_PASSWORD],
      });
      return;
    }

    login(username, password)
      .then(() => {
        this.setState({
          isLoading: false,
          errors: null,
          isPasswordExpired: false,
          displayProfileForm: isReadOnly,
        });
        onLogin();
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          const errors = error.response.data;
          const isPasswordExpired = errors?.errors?.[0]?.errorCode === 'saagie.generic' && errors?.errors?.[0]?.messages?.[0]?.errorCode === 'expired';
          if (isPasswordExpired) {
            this.setState({
              isLoading: false,
              errors: null,
              isPasswordExpired,
              displayProfileForm: isReadOnly,
            });
          } else {
            this.setState({
              isLoading: false,
              errors: (error.response.status !== 401) ? [UNKNOWN_ERROR] : errors
                ?.errors?.flatMap((e) => e.messages?.map((m) => m.defaultMessage)) ?? UNKNOWN_ERROR,
            });
          }
        } else {
          this.setState({
            isLoading: false,
            errors: [UNKNOWN_ERROR],
          });
        }
      });
  };

  handleSubmitExpiredPassword = (event) => {
    event.preventDefault();
    const {
      username,
      password,
      newPassword,
      newPasswordConfirmation,
    } = this.state;

    this.setState({
      isLoading: true,
      errors: null,
    });

    if (!newPassword || !newPasswordConfirmation) {
      this.setState({
        isLoading: false,
        errors: [EMPTY_NEW_PASSWORD],
      });
      return;
    }

    if (newPassword !== newPasswordConfirmation) {
      this.setState({
        isLoading: false,
        errors: [NEW_PASSWORDS_NO_MATCH],
      });
      return;
    }

    changePassword(username, password, newPassword)
      .then(() => login(username, newPassword))
      .then(() => getProfile(username))
      .then((response) => {
        const { job, email } = response.data;
        const isCompletedProfile = !!job && !!email;
        if (isCompletedProfile) {
          this.props.onLogin();
        } else {
          this.setState({
            errors: null,
            isPasswordExpired: false,
            displayProfileForm: true,
          });
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          const errors = error.response.data;
          this.setState({
            isLoading: false,
            errors: errors?.errors
              ?.flatMap((e) => e.messages?.map((m) => m.defaultMessage)) ?? UNKNOWN_ERROR,
          });
        } else {
          this.setState({
            isLoading: false,
            errors: [UNKNOWN_ERROR],
          });
        }
      });
  };

  render() {
    const { onLogin, isReadOnly } = this.props;
    const {
      username,
      password,
      newPassword,
      newPasswordConfirmation,
      isLoading,
      errors,
      isPasswordExpired,
      displayProfileForm,
    } = this.state;

    if (isPasswordExpired && !isReadOnly) {
      return (
        <OfflineLayout>
          <h2 className="sui-h-text-center sui-h-mb-md">
            Change your password
          </h2>
          <p className="sui-h-text-center sui-h-mb-md">
            To continue, you need to change your password for security reasons.
          </p>
          <ChangePasswordForm
            errors={errors}
            username={username}
            password={password}
            newPassword={newPassword}
            newPasswordConfirmation={newPasswordConfirmation}
            isLoading={isLoading}
            onChange={this.handleInput}
            onSubmit={this.handleSubmitExpiredPassword}
          />
        </OfflineLayout>
      );
    }

    if (displayProfileForm) {
      return (
        <OfflineLayout>
          <h2 className="sui-h-text-center sui-h-mb-md">
            Profile
          </h2>
          <ProfileFormLogin login={username} onLogin={onLogin} isReadOnly={isReadOnly} />
        </OfflineLayout>
      );
    }
    return (
      <OfflineLayout>
        <LoginForm
          errors={errors}
          username={username}
          password={password}
          isLoading={isLoading}
          onChange={this.handleInput}
          onSubmit={this.handleSubmitLogin}
          isReadOnly={isReadOnly}
        />
      </OfflineLayout>
    );
  }
}

Login.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired,
};

export default Login;
